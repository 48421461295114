@import url('https://fonts.googleapis.com/icon?family=Material+Icons');


#main-table td,th{

    border: 1px solid #ddd;
    padding: 8px;
}

.row_score{

    color:#FF5733;
}