#right-panel{


	height:850px;
	overflow-y: scroll;
}
#m-root{

	margin-left: 10px;
}
.intro{
	border: solid 2px #898989;
 	padding: 5px 5px;
	
}
button.active{

	background-color: #039be5!important;
}
button.select-part{

	margin-right:5px;
}

.all-answers{
	border: dashed 2px #898989;
	padding: 5px 5px;

}