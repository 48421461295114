
.main-intro{


    border: 2px solid black;
    padding:15px 15px;
}

.select-part-button{

	margin-right:5px;
}